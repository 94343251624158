::placeholder {
    color: darkblue !important;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

/* Modal Content */
.modal-content {
    position: fixed;
    bottom: 0;
    background-color: #fefefe;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

#loader_form {
    position: relative;
    top: 7%;
    left: 6%;
    overflow: hidden;
    background-color: transparent;
}

.modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

.modal-body {
    height: 100px;
    position: relative;
    left: 45%;
}

.modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/* Add Animation */
@-webkit-keyframes slideIn {
    from {bottom: -300px; opacity: 0}
    to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
    from {bottom: -300px; opacity: 0}
    to {bottom: 0; opacity: 1}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
}

.background-wrong {
    background-color: #fda0a0 !important;
}

.app-pagination {
    display: flex;
    margin-top: 20px;
    border-radius: 6px;
    overflow: hidden;
    width: fit-content;
}

.app-pagination-button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #eee;
    padding: 6px 10px;
    color: #444;
}

.app-pagination-button:focus {
    background-color: #73a5a0;
    color: #fff;
}

.app-pagination-button.active {
    background-color: #00897b;
    color: #fff;
}

.app-pagination-divider {
    background-color: #eee;
    color: #adadad;
}

.input-field #TableSearch {
    max-width: 360px;
}

.hidden {
    display: none!important;
}